import {
  ChannelAnalyzeDTO,
  CustomerFromEnum,
  LoanChannelAnalyzeDTO,
} from '@/api';
import { crmProxy } from '@/apis/api';
import DatetimePicker from '@/components/DatetimePicker';
import DepartmentSelect from '@/components/DepartmentSelect';
import FromSelect from '@/components/FromSelect';
import useCompany from '@/hooks/company';
import { findAllDepartmentId, formatEndTime, formatStartTime } from '@/utils';
import { useRequest } from 'ahooks';
import {
  Button,
  Col,
  Rate,
  Row,
  Space,
  Statistic,
  Tag,
  TagProps,
  Tooltip,
} from 'antd';
import { ColumnFilterItem } from 'antd/es/table/interface';
import { ColumnType } from 'antd/es/table';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Table } from 'antd';

const RateStar = ({
  value,
  dto,
  color,
}: {
  value: number;
  dto: Pick<
    ChannelAnalyzeDTO,
    'star0' | 'star1' | 'star2' | 'star3' | 'star4' | 'star5'
  >;
  color?: TagProps['color'];
}) => {
  const total =
    Number(dto.star0) +
    Number(dto.star1) +
    Number(dto.star2) +
    Number(dto.star3) +
    Number(dto.star4) +
    Number(dto.star5);

  return (
    <Space align="end" style={{ justifyContent: 'space-between' }}>
      <span style={{}}>{value}</span>
      <div style={{}}>
        <Tag color={color || 'blue'}>
          {total ? `${((value * 100) / total).toFixed(2)}%` : ''}
        </Tag>
      </div>
    </Space>
  );
};

const LoanStatistical = ({ loan }: { loan: LoanChannelAnalyzeDTO }) => {
  if (!loan) {
    return <span>-</span>;
  }
  return (
    <Space>
      <Statistic
        value={new BigNumber(loan.loanAmount || 0).div(100).toString()}
        prefix="¥"
        valueStyle={{ fontSize: '14px' }}
      />
      <span>(</span>
      <Statistic
        value={new BigNumber(loan.loanBrokerage || 0).div(100).toString()}
        prefix="¥"
        valueStyle={{ fontSize: '14px' }}
      />
      <span>)</span>
    </Space>
  );
};

interface CompanyAndDepartment {
  companyId: I.ID;
  departmentId: I.ID;
}

const defaultCreatedAt: [dayjs.Dayjs, dayjs.Dayjs] = [
  dayjs().startOf('month'),
  dayjs().endOf('month'),
];

export default function ChannelAnalyse() {
  const { loading: companyLoading, companies } = useCompany();

  const [companyAndDepartment, setCompanyAndDepartment] = useState<
    CompanyAndDepartment[]
  >([]);

  const [createdAt, setCreatedAt] =
    useState<[dayjs.Dayjs, dayjs.Dayjs]>(defaultCreatedAt);
  const [fromList, setFromList] = useState<CustomerFromEnum>();

  const {
    loading: requestLoading,
    data,
    refreshAsync,
  } = useRequest(
    () => {
      return Promise.all(
        (companies || [])
          ?.filter((c) => {
            if (companyAndDepartment.length === 0) {
              return true;
            }
            // 找到所选的所有的 companyId
            return companyAndDepartment
              .map((cd) => cd.companyId)
              .includes(c.id);
          })
          .map((c) => {
            // 找到所选的所有的 departmentId
            const cd = companyAndDepartment.find((cd) => cd.companyId === c.id);
            const depIds = cd?.departmentId
              ? findAllDepartmentId(
                  c.info.departments!,
                  cd.departmentId || null,
                ).map((item) => item.id)
              : [];
            return crmProxy('POST', c.id, 'channel_analyze', {
              data: {
                start: formatStartTime(createdAt[0]),
                end: formatEndTime(createdAt[1]),
                depIds,
                fromEnums: fromList,
              },
            }).then((res) => {
              return res as ChannelAnalyzeDTO[];
            });
          }),
      ).then((res) => {
        return res.flat().reduce((acc, cur) => {
          const index = acc.findIndex((item) => item.channel === cur.channel);

          if (index === -1) {
            return [...acc, cur];
          }
          const a = acc[index];
          const loan = cur.loan || { channel: a.channel };
          if (a.loan) {
            // merge loan
            loan.cnt = Number(loan.cnt || 0) + Number(a.loan.cnt || 0);
            loan.customerCount =
              Number(loan.customerCount || 0) +
              Number(a.loan.customerCount || 0);
            loan.loanAmount = new BigNumber(loan.loanAmount || 0)
              .plus(a.loan.loanAmount || 0)
              .toString();
            loan.loanBrokerage = new BigNumber(loan.loanBrokerage || 0)
              .plus(a.loan.loanBrokerage || 0)
              .toString();
          }

          const item: ChannelAnalyzeDTO = {
            ...a,
            loan,
            cnt: Number(a.cnt || 0) + Number(cur.cnt || 0),
            star0: Number(a.star0 || 0) + Number(cur.star0 || 0),
            star1: Number(a.star1 || 0) + Number(cur.star1 || 0),
            star2: Number(a.star2 || 0) + Number(cur.star2 || 0),
            star3: Number(a.star3 || 0) + Number(cur.star3 || 0),
            star4: Number(a.star4 || 0) + Number(cur.star4 || 0),
            star5: Number(a.star5 || 0) + Number(cur.star5 || 0),
            stateSignedCount:
              Number(a.stateSignedCount || 0) +
              Number(cur.stateSignedCount || 0),
            stateVisitedCount:
              Number(a.stateVisitedCount || 0) +
              Number(cur.stateVisitedCount || 0),
          };
          return [...acc.slice(0, index), item, ...acc.slice(index + 1)];
        }, [] as ChannelAnalyzeDTO[]);
      });
    },
    {
      refreshDeps: [createdAt, companies, fromList, companyAndDepartment],
    },
  );

  const channelFilter: ColumnFilterItem[] = useMemo(() => {
    return (
      data
        ?.map((item) => ({
          text: item.channel || '',
          value: item.channel || '',
        }))
        .filter((item) => item.text.length) || []
    );
  }, [data]);

  const columns: ColumnType<ChannelAnalyzeDTO>[] = [
    {
      dataIndex: 'channel',
      title: (
        <Tooltip title="只统计数据源为 RMS 的数据">
          <Space>
            <InfoCircleOutlined />
            <span>渠道</span>
          </Space>
        </Tooltip>
      ),
      filters: channelFilter,
      filterSearch: true,
      filtered: true,
      fixed: 'left',
      onFilter: (value: string | number | boolean, record) =>
        record.channel === value,
      align: 'left',
      width: 60,
    },
    {
      dataIndex: 'cnt',
      title: '客户总数',
      sorter: (a, b) => (a.cnt > b.cnt ? 1 : -1),
      align: 'right',
      width: 52,
    },
    {
      dataIndex: 'stateVisitedCount',
      title: '已上门',
      sorter: (a, b) => (a.stateVisitedCount > b.stateVisitedCount ? 1 : -1),
      align: 'right',
      width: 43,
    },
    {
      dataIndex: 'stateSignedCount',
      sorter: (a, b) => (a.stateSignedCount > b.stateSignedCount ? 1 : -1),
      title: '已签约',
      align: 'right',
      width: 43,
    },
    {
      dataIndex: 'loan',
      title: '已放款',
      sorter: (a, b) =>
        (a.loan?.customerCount || 0) > (b.loan?.customerCount || 0) ? 1 : -1,
      render: (loan: LoanChannelAnalyzeDTO) => {
        return <span>{loan?.customerCount || 0}</span>;
      },
      align: 'right',
      width: 43,
    },
    {
      dataIndex: 'loan',
      title: '放款额度（佣金）',
      width: 130,
      sorter: (a, b) =>
        (a.loan?.loanAmount || 0) > (b.loan?.loanAmount || 0) ? 1 : -1,
      render: (loan: LoanChannelAnalyzeDTO) => {
        return <LoanStatistical loan={loan} />;
      },
      align: 'right',
    },
    {
      dataIndex: 'star5',
      sorter: (a, b) => (a.star5 > b.star5 ? 1 : -1),
      title: <Rate disabled value={5} count={5} style={{ fontSize: '12px' }} />,
      render: (value: number, dto) => {
        return <RateStar value={value} dto={dto} />;
      },
      align: 'right',
      width: 67,
    },
    {
      dataIndex: 'star4',
      sorter: (a, b) => (a.star4 > b.star4 ? 1 : -1),
      title: <Rate disabled value={4} count={4} style={{ fontSize: '12px' }} />,
      render: (value: number, dto) => {
        return <RateStar value={value} dto={dto} />;
      },
      align: 'right',
      width: 60,
    },
    {
      dataIndex: 'star3',
      sorter: (a, b) => (a.star3 > b.star3 ? 1 : -1),
      title: <Rate disabled value={3} count={3} style={{ fontSize: '12px' }} />,
      render: (value: number, dto) => {
        return <RateStar value={value} dto={dto} />;
      },
      align: 'right',
      width: 60,
    },
    {
      dataIndex: 'star2',
      sorter: (a, b) => (a.star2 > b.star2 ? 1 : -1),
      title: <Rate disabled value={2} count={2} style={{ fontSize: '12px' }} />,
      render: (value: number, dto) => {
        return <RateStar value={value} dto={dto} />;
      },
      align: 'right',
      width: 60,
    },
    {
      dataIndex: 'star1',
      align: 'right',
      sorter: (a, b) => (a.star1 > b.star1 ? 1 : -1),
      title: <Rate disabled value={1} count={1} style={{ fontSize: '12px' }} />,
      render: (value: number, dto) => {
        return <RateStar value={value} dto={dto} />;
      },
      width: 60,
    },
    {
      dataIndex: 'star0',
      sorter: (a, b) => (a.star0 > b.star0 ? 1 : -1),
      title: <Rate disabled value={0} count={0} style={{ fontSize: '12px' }} />,
      align: 'right',
      render: (value: number, dto) => {
        return <RateStar value={value} dto={dto} />;
      },
      width: 60,
    },
    {
      title: (
        <Tooltip title="3 星以及以上为优质">
          <Space>
            <InfoCircleOutlined />
            <span>优质率</span>
          </Space>
        </Tooltip>
      ),
      width: 60,
      align: 'right',
      sorter: (a, b) => {
        const cal = (dto: ChannelAnalyzeDTO) => {
          const total =
            Number(dto.star0) +
            Number(dto.star1) +
            Number(dto.star2) +
            Number(dto.star3) +
            Number(dto.star4) +
            Number(dto.star5);
          return (
            ((Number(dto.star3) + Number(dto.star4) + Number(dto.star5)) *
              100) /
            total
          );
        };
        return cal(a) > cal(b) ? 1 : -1;
      },
      render: (_, dto) => {
        return (
          <RateStar
            color="yellow"
            value={Number(dto.star3) + Number(dto.star4) + Number(dto.star5)}
            dto={dto}
          />
        );
      },
    },
  ];

  const loading = companyLoading || requestLoading;
  return (
    <Row>
      <Col span={24}>
        <Space>
          <FromSelect
            disabled={loading}
            value={fromList}
            onChange={(v) => setFromList(v)}
          />
          <DepartmentSelect
            multiple
            disabled={loading}
            value={companyAndDepartment.map(
              (item) => `${item.companyId}-${item.departmentId}`,
            )}
            onClear={() => {
              setCompanyAndDepartment([]);
            }}
            onChange={(v: string[]) => {
              const value = v
                .map((item) => {
                  const vv = item.split('-').map((vv: string) => parseInt(vv));
                  return {
                    companyId: vv[0],
                    departmentId: vv[1],
                  };
                })
                .reduce((acc, cur) => {
                  const vvv = acc[cur.companyId] || [];
                  return {
                    ...acc,
                    [cur.companyId]: [...vvv, cur.departmentId],
                  };
                }, {} as Record<I.ID, I.ID[]>);
              console.log(value);
              setCompanyAndDepartment(
                v.map((item) => {
                  const vv = item.split('-').map((vv: string) => parseInt(vv));
                  return {
                    companyId: vv[0],
                    departmentId: vv[1],
                  };
                }),
              );
            }}
          />
          <DatetimePicker
            disabled={loading}
            value={createdAt}
            onChange={(v) => {
              const start = v.startedAt
                ? dayjs(v.startedAt)
                : defaultCreatedAt[0];
              const end = v.endedAt ? dayjs(v.endedAt) : defaultCreatedAt[1];
              setCreatedAt([start, end]);
            }}
          />
          <Button type="primary" loading={loading} onClick={refreshAsync}>
            查询
          </Button>
        </Space>
      </Col>
      <Col span={24} style={{ marginTop: '20px' }}>
        <Table
          size="small"
          scroll={{ x: 1500 }}
          bordered
          tableLayout="fixed"
          summary={(list) => {
            if (list.length === 0) {
              return null;
            }
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell key={0} index={0}>
                    合计
                  </Table.Summary.Cell>
                  <Table.Summary.Cell key={1} index={1} align="right">
                    {list.reduce((pre, cur) => pre + Number(cur.cnt), 0)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell key={2} index={2} align="right">
                    {/* 上门 */}
                    {list.reduce(
                      (pre, cur) => pre + Number(cur.stateVisitedCount),
                      0,
                    )}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell key={3} index={3} align="right">
                    {/* 签约 */}
                    {list.reduce(
                      (pre, cur) => pre + Number(cur.stateSignedCount),
                      0,
                    )}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell key={4} index={4} align="right">
                    {/* 已放款 */}
                    {list.reduce(
                      (pre, cur) => pre + Number(cur.loan?.customerCount || 0),
                      0,
                    )}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell key={5} index={5} align="right">
                    <Space>
                      <Statistic
                        value={
                          list.reduce(
                            (pre, cur) =>
                              pre + Number(cur.loan?.loanAmount || 0),
                            0,
                          ) / 100
                        }
                        prefix="¥"
                        valueStyle={{ fontSize: '14px' }}
                      />
                      <span>(</span>
                      <Statistic
                        value={
                          list.reduce(
                            (pre, cur) =>
                              pre + Number(cur.loan?.loanBrokerage || 0),
                            0,
                          ) / 100
                        }
                        prefix="¥"
                        valueStyle={{ fontSize: '14px' }}
                      />
                      <span>)</span>
                    </Space>
                  </Table.Summary.Cell>
                  {[5, 4, 3, 2, 1, 0].map((star, idx) => {
                    return (
                      <Table.Summary.Cell
                        key={star}
                        index={idx + 6}
                        align="right"
                      >
                        <RateStar
                          value={list.reduce(
                            // @ts-ignore
                            (pre, cur) =>
                              // @ts-ignore
                              pre + Number(cur[`star${star}`] || 0),
                            0,
                          )}
                          dto={list.reduce(
                            (prev, cur) => {
                              return {
                                star0: Number(prev.star0) + Number(cur.star0),
                                star1: Number(prev.star1) + Number(cur.star1),
                                star2: Number(prev.star2) + Number(cur.star2),
                                star3: Number(prev.star3) + Number(cur.star3),
                                star4: Number(prev.star4) + Number(cur.star4),
                                star5: Number(prev.star5) + Number(cur.star5),
                              };
                            },
                            {
                              star0: 0,
                              star1: 0,
                              star2: 0,
                              star3: 0,
                              star4: 0,
                              star5: 0,
                            },
                          )}
                        />
                      </Table.Summary.Cell>
                    );
                  })}
                  <Table.Summary.Cell key={12} index={12} align="right">
                    <RateStar
                      color="yellow"
                      value={list.reduce((prev, cur) => {
                        return (
                          prev +
                          Number(cur.star3) +
                          Number(cur.star4) +
                          Number(cur.star5)
                        );
                      }, 0)}
                      dto={list.reduce(
                        (prev, cur) => {
                          return {
                            star0: Number(prev.star0) + Number(cur.star0),
                            star1: Number(prev.star1) + Number(cur.star1),
                            star2: Number(prev.star2) + Number(cur.star2),
                            star3: Number(prev.star3) + Number(cur.star3),
                            star4: Number(prev.star4) + Number(cur.star4),
                            star5: Number(prev.star5) + Number(cur.star5),
                          };
                        },
                        {
                          star0: 0,
                          star1: 0,
                          star2: 0,
                          star3: 0,
                          star4: 0,
                          star5: 0,
                        },
                      )}
                    />
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
          loading={loading}
          pagination={false}
          columns={columns}
          dataSource={data}
          rowKey={'channel'}
        />
      </Col>
    </Row>
  );
}
