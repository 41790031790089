import { HandleAnalyseDTO, UserStateEnum } from '@/api';
import { crmProxy } from '@/apis/api';
import DatetimePicker from '@/components/DatetimePicker';
import DepartmentSelect from '@/components/DepartmentSelect';
import useCompany from '@/hooks/company';
import {
  findAllDepartmentId,
  findChildrenDepartment,
  flatDepartment,
  formatEndTime,
  formatStartTime,
  sortName,
} from '@/utils';
import { useRequest } from 'ahooks';
import { Button, Col, Row, Segmented, Space, Statistic, Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';

type CompanyHandleAnalyseDTO = HandleAnalyseDTO & {
  company: I.Company & { info: I.CompanyInfo };
};

type Data = HandleAnalyseDTO & { name?: string; ix: string };

type Type = 'person' | 'department' | 'group' | 'company';

interface CompanyAndDepartment {
  companyId: I.ID;
  departmentId: I.ID;
}

const defaultCreatedAt: [dayjs.Dayjs, dayjs.Dayjs] = [
  dayjs().startOf('month'),
  dayjs().endOf('month'),
];

const flatData = (
  data: CompanyHandleAnalyseDTO[],
  ix: string,
  name: string,
): Data => {
  const value = data.reduce<Data>(
    (acc, item) => ({
      ...item,
      ix,
      name,
      loanAmount: (BigInt(acc.loanAmount) + BigInt(item.loanAmount)).toString(),
      brokerage: (BigInt(acc.brokerage) + BigInt(item.brokerage)).toString(),
      comment: acc.comment + item.comment,
      distinctComment: acc.distinctComment + item.distinctComment,
      loanCount: acc.loanCount + item.loanCount,
      sign: acc.sign + item.sign,
      visitor: acc.visitor + item.visitor,
      visitorCurMonth: acc.visitorCurMonth + item.visitorCurMonth,
      withAgent: acc.withAgent + item.withAgent,
      withAgentCurMonth: acc.withAgentCurMonth + item.withAgentCurMonth,
      withAgentSign: acc.withAgentSign + item.withAgentSign,
      callInCount: acc.callInCount + item.callInCount,
      callInDuration: acc.callInDuration + item.callInDuration,
      callOutCount: acc.callOutCount + item.callOutCount,
      callOutDuration: acc.callOutDuration + item.callOutDuration,
    }),
    {
      brokerage: 0,
      comment: 0,
      distinctComment: 0,
      loanAmount: 0,
      loanCount: 0,
      sign: 0,
      userId: 0,
      userName: '',
      userState: UserStateEnum.ACTIVE,
      visitor: 0,
      visitorCurMonth: 0,
      withAgent: 0,
      withAgentCurMonth: 0,
      withAgentSign: 0,
      callInCount: 0,
      callInDuration: 0,
      callOutCount: 0,
      callOutDuration: 0,
      ix: '',
    },
  );
  return value;
};

const LoanStatistic = ({ data }: { data: Data }) => {
  const { loanCount, loanAmount } = data;
  return (
    <Space>
      <span>{loanCount}</span>
      <span>
        （
        <Statistic
          style={{ display: 'inline-block' }}
          value={Number(loanAmount) / 100}
          prefix="¥"
          valueStyle={{ fontSize: '14px' }}
        />
        ）
      </span>
    </Space>
  );
};

const CallDuration = ({
  count,
  duration,
}: {
  count: number;
  duration: number;
}) => {
  const durationStr = useMemo(() => {
    if (duration >= 3600) {
      return `${Math.floor(duration / 3600)} 小时`;
    }
    if (duration >= 60) {
      return `${Math.floor(duration / 60)} 分钟`;
    }
    if (!duration) {
      return '-';
    }
    return `${duration} 秒`;
  }, [duration]);
  return (
    <Space>
      <span>{count || '-'}</span>
      <span>/</span>
      <span>{durationStr}</span>
    </Space>
  );
};

const columns: ColumnType<Data>[] = [
  // {
  //   dataIndex: 'ix',
  // },
  {
    dataIndex: 'name',
    align: 'left',
    title: '名称',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => sortName(a.name, b.name),
    width: 80,
  },
  {
    title: '跟进次数/人数',
    sorter: (a, b) => (a.comment > b.comment ? 1 : -1),
    render: (_, { comment, distinctComment }) => {
      return (
        <Space>
          <span>{comment}</span>
          <span>/</span>
          <span>{distinctComment}</span>
        </Space>
      );
    },
    width: 120,
  },
  {
    title: '总上门量/月度',
    sorter: (a, b) => (a.visitor > b.visitor ? 1 : -1),
    render: (_, data) => {
      return (
        <Space>
          <span>{data.visitor}</span>
          <span>/</span>
          <span>{data.visitorCurMonth}</span>
        </Space>
      );
    },
    width: 120,
  },
  {
    title: '签约量',
    sorter: (a, b) => (a.sign > b.sign ? 1 : -1),
    render: (_, data) => {
      const { sign, visitor } = data;
      if (visitor === 0) {
        return <span>0</span>;
      }
      const percentage = (sign * 100.0) / visitor;
      return (
        <Space>
          <span>
            {sign}（{percentage.toFixed(2)}%）
          </span>
        </Space>
      );
    },
    width: 80,
  },
  {
    title: '拓展上门量/月度',
    sorter: (a, b) => (a.withAgent > b.withAgent ? 1 : -1),
    render: (_, data) => {
      const { withAgent, withAgentCurMonth } = data;
      return (
        <Space>
          <span>{withAgent}</span>
          <span>/</span>
          <span>{withAgentCurMonth}</span>
        </Space>
      );
    },
    width: 120,
  },
  {
    title: '拓展成功量',
    sorter: (a, b) => (a.withAgentSign > b.withAgentSign ? 1 : -1),
    render: (_, data) => {
      const { withAgentSign, withAgent } = data;
      if (withAgent === 0) {
        return <span>0</span>;
      }
      const percentage = (withAgentSign * 100.0) / withAgent;
      return (
        <Space>
          <span>
            {withAgentSign}（{percentage.toFixed(2)}%）
          </span>
        </Space>
      );
    },
    width: 100,
  },
  {
    title: '放款成功',
    sorter: (a, b) => (a.loanCount > b.loanCount ? 1 : -1),
    dataIndex: 'loanCount',
    render: (_, data) => {
      return <LoanStatistic data={data} />;
    },
    width: 100,
  },
  {
    title: '创收',
    sorter: (a, b, order) => {
      return Number(a.brokerage || 0) > Number(b.brokerage || 0) ? 1 : -1;
    },
    showSorterTooltip: true,
    dataIndex: 'brokerage',
    render: (_, { brokerage }) => {
      return (
        <Statistic
          value={Number(brokerage) / 100}
          prefix="¥"
          valueStyle={{ fontSize: '14px' }}
        />
      );
    },
    width: 80,
  },
  {
    title: '平均点位',
    render: (_, { brokerage, loanAmount }) => {
      if (loanAmount === 0) {
        return <span>0</span>;
      }
      const percentage = (Number(brokerage) * 100.0) / Number(loanAmount);
      return <span>{percentage.toFixed(2)}%</span>;
    },
    width: 100,
  },
  {
    title: '呼出',
    render: (_, { callOutCount, callOutDuration }) => {
      return <CallDuration count={callOutCount} duration={callOutDuration} />;
    },
    width: 100,
  },
  {
    title: '呼入',
    render: (_, { callInCount, callInDuration }) => {
      return <CallDuration count={callInCount} duration={callInDuration} />;
    },
    width: 100,
  },
];

export default function HandleAnalyse() {
  const { loading: companyLoading, companies: currentCompanies } = useCompany();

  const [companyAndDepartment, setCompanyAndDepartment] = useState<
    CompanyAndDepartment[]
  >([]);

  const [type, setType] = useState<Type>('company');

  const [dateRange, setDateRange] =
    useState<[dayjs.Dayjs, dayjs.Dayjs]>(defaultCreatedAt);

  const {
    data: rawData,
    loading: requestLoading,
    refreshAsync,
  } = useRequest(
    () => {
      return Promise.all(
        (currentCompanies || []).map((c) => {
          const params = {
            start: formatStartTime(dateRange[0]),
            end: formatEndTime(dateRange[1]),
            currentMonthStart: formatStartTime(
              dayjs().startOf('month').hour(0).minute(0).second(0),
            ),
            currentMonthEnd: formatEndTime(dayjs().endOf('month')),
          };
          return crmProxy('POST', c.id, 'handle_analyze', {
            data: params,
          }).then((result) => {
            return (result as HandleAnalyseDTO[]).map((item) => ({
              ...item,
              company: c,
            }));
          });
        }),
      ).then((result) => result.flat());
    },
    {
      refreshDeps: [dateRange, currentCompanies],
    },
  );

  const companies = useMemo(() => {
    if (companyAndDepartment.length === 0) {
      return currentCompanies || [];
    }
    return (currentCompanies || []).filter(
      (c) => companyAndDepartment.findIndex((cd) => cd.companyId === c.id) > -1,
    );
  }, [currentCompanies, companyAndDepartment]);

  const data: Data[] = useMemo(() => {
    if (!rawData) {
      return [];
    }

    switch (type) {
      case 'company': {
        return companies.map((c) => {
          const value = rawData
            // .filter((item) => item.userState === UserStateEnum.ACTIVE)
            .filter((item) => item.company.id === c.id);
          return flatData(value, c.id.toString(), c.name);
        });
      }
      case 'department': {
        // 找到所有的大区
        // 按照部门就要先按照公司排序
        const allDirectChildrent =
          (companyAndDepartment?.length || 0) > 0
            ? (companyAndDepartment || [])
                .map((c) => {
                  const company = companies?.find(
                    (item) => item.id === c.companyId,
                  );
                  if (!company) {
                    throw new Error('公司不存在');
                  }
                  return company.info.departments
                    .filter((dep) => !dep.parentId)
                    .filter((dep) => {
                      if (c.departmentId) {
                        return dep.id === c.departmentId;
                      }
                      return true;
                    })
                    .map((dep) => ({
                      ...dep,
                      companyId: company.id,
                      companyName: company.name,
                      company,
                      name: `${company.name}-${dep.name}`,
                      id: `${company.id}-${dep.id}`,
                      depId: dep.id,
                    }));
                })
                .flat()
            : companies
                ?.map((c) => {
                  return c.info.departments
                    .filter((dep) => !dep.parentId)
                    .map((dep) => ({
                      ...dep,
                      companyId: c.id,
                      companyName: c.name,
                      company: c,
                      name: `${c.name}-${dep.name}`,
                      id: `${c.id}-${dep.id}`,
                      depId: dep.id,
                    }));
                })
                .flat();
        return (
          Object.values(
            allDirectChildrent.reduce((acc, current) => {
              acc[current.id] = current;
              return acc;
            }, {} as Record<string, (typeof allDirectChildrent)[0]>),
          )
            ?.sort((a, b) => sortName(a.name, b.name))
            .map((item) => {
              const name = `${flatDepartment(
                item.company.info.departments,
                item.id,
              )
                .filter((v) => v.id !== item.id)
                .map((item) => item.name)
                .reverse()
                .join('/')} ${item.name}`;

              const children = findAllDepartmentId(
                item.company.info.departments,
                item.depId,
              ).map((dep) => `${item.companyId}-${dep.id}`);

              const value = rawData.filter((item) =>
                children.includes(`${item.company.id}-${item.departmentId}`),
              );
              return flatData(value, item.id, name);
            }) || []
        );
      }
      case 'group':
        // 找到所有的一级小组
        const selectedDepartments =
          (companyAndDepartment?.length || 0) > 0
            ? (companyAndDepartment || [])
                // .filter((c) => c.id === companyAndDepartment?.[0])
                .map((c) => {
                  const company = companies?.find(
                    (item) => item.id === c.companyId,
                  );
                  if (!company) {
                    throw new Error('公司不存在');
                  }
                  return findAllDepartmentId(
                    company?.info.departments,
                    c.departmentId,
                  ).map((dep) => ({ company, dep }));
                })
                .flat()
            : companies
                ?.map((c) =>
                  findAllDepartmentId(c.info.departments, null).map((dep) => ({
                    company: c,
                    dep,
                  })),
                )
                .flat();
        const allDeps = Object.values(
          selectedDepartments.reduce((acc, current) => {
            acc[`${current.company.id}-${current.dep.id}`] = current;
            return acc;
          }, {} as Record<string, { company: I.Company & { info: I.CompanyInfo }; dep: I.Department }>),
        )
          ?.filter(
            (item) =>
              findChildrenDepartment(item.company.info.departments, item.dep.id)
                .length === 0,
          )
          .map((item) => {
            return {
              ...item,
              id: `${item.company.id}-${item.dep.id}`,
            };
          });
        return (
          allDeps?.map((item) => {
            const name = `${item.company.name}-${flatDepartment(
              item.company.info.departments,
              item.dep.id,
            )
              .filter((v) => v.id !== item.id)
              .map((item) => item.name)
              .reverse()
              .join('/')}`;
            const value = rawData.filter((i) => {
              return (
                i.departmentId === item.dep.id &&
                i.company.id === item.company.id
              );
            });
            return flatData(value, item.id, name);
          }) || []
        );
    }

    if (companyAndDepartment.length > 0) {
      const selectedDepartments = companyAndDepartment
        .map((item) => {
          const company = currentCompanies?.find(
            (c) => c.id === item.companyId,
          );
          if (!company) {
            throw new Error('公司不存在');
          }
          // 找到选择的部门的所有的部门
          return findAllDepartmentId(
            company.info.departments,
            item.departmentId,
          ).map((dep) => ({
            depId: dep.id,
            name: dep.name,
            id: `${item.companyId}-${dep.id}`,
            company,
          }));
        })
        .flat();
      const uniqDeps = Object.values(
        selectedDepartments.reduce((acc, current) => {
          acc[current.id] = current;
          return acc;
        }, {} as Record<string, (typeof selectedDepartments)[0]>),
      );
      return rawData
        .filter((user) => user.userState === UserStateEnum.ACTIVE)
        .filter(
          (user) =>
            uniqDeps.findIndex(
              (dep) => dep.id === `${user.company.id}-${user.departmentId}`,
            ) > -1,
        )
        .map((item) => {
          const company = currentCompanies?.find(
            (c) => c.id === item.company.id,
          );
          if (!company) {
            throw new Error('公司不存在');
          }
          const name = `${company.name}-${flatDepartment(
            item.company.info.departments,
            item.departmentId,
          )
            .map((item) => item.name)
            .reverse()
            .join('/')} ${item.userName}`;

          return {
            ...item,
            ix: `${item.company.id}-${item.userId}`,
            name,
          };
        });
    }

    return rawData
      .filter((user) => user.userState === UserStateEnum.ACTIVE) // 过滤有效的用户
      ?.map((item) => {
        const company = currentCompanies?.find((c) => c.id === item.company.id);
        if (!company) {
          throw new Error('公司不存在');
        }
        const name = `${company.name}-${flatDepartment(
          item.company.info.departments,
          item.departmentId,
        )
          .map((item) => item.name)
          .reverse()
          .join('/')} ${item.userName}`;

        return {
          ...item,
          ix: `${item.company.id}-${item.userId}`,
          name,
        };
      });
  }, [companies, companyAndDepartment, currentCompanies, rawData, type]);

  console.log(data);

  const loading = companyLoading || requestLoading;
  return (
    <Row>
      <Col span={24}>
        <Space>
          <Segmented
            options={[
              {
                label: '个人',
                value: 'person',
              },
              {
                label: '小组',
                value: 'group',
              },
              {
                label: '区域',
                value: 'department',
              },
              {
                label: '公司',
                value: 'company',
              },
            ]}
            value={type}
            onChange={(v) => setType(v as Type)}
            disabled={loading}
          />
          <DepartmentSelect
            multiple
            disabled={loading}
            value={companyAndDepartment.map(
              (item) => `${item.companyId}-${item.departmentId}`,
            )}
            onClear={() => {
              setCompanyAndDepartment([]);
            }}
            onChange={(v: string[]) => {
              setCompanyAndDepartment(
                v.map((item) => {
                  const vv = item.split('-').map((vv: string) => parseInt(vv));
                  return {
                    companyId: vv[0],
                    departmentId: vv[1],
                  };
                }),
              );
            }}
          />
          <DatetimePicker
            disabled={loading}
            value={dateRange}
            onChange={(v) => {
              const start = v.startedAt
                ? dayjs(v.startedAt)
                : defaultCreatedAt[0];
              const end = v.endedAt ? dayjs(v.endedAt) : defaultCreatedAt[1];
              setDateRange([start, end]);
            }}
          />
          <Button type="primary" loading={loading} onClick={refreshAsync}>
            查询
          </Button>
        </Space>
      </Col>
      <Col span={24} style={{ marginTop: '20px' }}>
        <Table
          summary={(data) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} align="left">
                    合计
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="right">
                    <Space>
                      <span>
                        {data?.reduce((acc, cur) => acc + cur.comment, 0)}
                      </span>
                      <span>/</span>
                      <span>
                        {data?.reduce(
                          (acc, cur) => acc + cur.distinctComment,
                          0,
                        )}
                      </span>
                    </Space>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="right">
                    <Space>
                      <span>
                        {data?.reduce((acc, cur) => acc + cur.visitor, 0)}
                      </span>
                      <span>/</span>
                      <span>
                        {data?.reduce(
                          (acc, cur) => acc + cur.visitorCurMonth,
                          0,
                        )}
                      </span>
                    </Space>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="right">
                    <span>
                      {data?.reduce((acc, cur) => acc + cur.sign, 0)}（
                      {(
                        (data?.reduce((prev, { sign, visitor }) => {
                          return prev + sign;
                        }, 0) *
                          100.0) /
                        data?.reduce((prev, { sign, visitor }) => {
                          return prev + visitor;
                        }, 0)
                      ).toFixed(2)}
                      %）
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} align="right">
                    <Space>
                      <span>
                        {data?.reduce((acc, cur) => acc + cur.withAgent, 0)}
                      </span>
                      <span>/</span>
                      <span>
                        {data?.reduce(
                          (acc, cur) => acc + cur.withAgentCurMonth,
                          0,
                        )}
                      </span>
                    </Space>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} align="right">
                    <Space>
                      <span>
                        {data?.reduce((acc, cur) => acc + cur.withAgentSign, 0)}
                      </span>
                      <span>（</span>
                      <span>
                        {(
                          (data?.reduce(
                            (acc, cur) => acc + cur.withAgentSign,
                            0,
                          ) /
                            data?.reduce(
                              (acc, cur) => acc + cur.withAgent,
                              0,
                            )) *
                          100.0
                        ).toFixed(2)}
                        %
                      </span>
                      <span>）</span>
                    </Space>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} align="right">
                    <Space>
                      <span>
                        {data?.reduce((acc, cur) => acc + cur.loanCount, 0)}
                      </span>
                      <span>（</span>
                      <Statistic
                        value={
                          data?.reduce(
                            (acc, cur) => acc + Number(cur.loanAmount),
                            0,
                          ) / 100
                        }
                        precision={2}
                        prefix="¥"
                        valueStyle={{ fontSize: '14px' }}
                      />
                      <span>）</span>
                    </Space>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align="right">
                    <Statistic
                      prefix="¥"
                      valueStyle={{ fontSize: '14px' }}
                      value={
                        data?.reduce(
                          (acc, cur) =>
                            acc + parseInt(String(cur.brokerage), 10),
                          0,
                        ) / 100
                      }
                    />
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8} align="right">
                    {(
                      (data?.reduce(
                        (prev, cur) => prev + Number(cur.brokerage || 0),
                        0,
                      ) /
                        data?.reduce(
                          (prev, cur) => prev + Number(cur.loanAmount),
                          0,
                        ) || 0) * 100
                    ).toFixed(2)}
                    %
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
          loading={loading}
          columns={columns.map((item) => {
            const align = item.align || 'right';
            return {
              ...item,
              align,
            };
          })}
          dataSource={data}
          size="small"
          bordered
          pagination={false}
          // rowKey={'ix'}
        />
      </Col>
    </Row>
  );
}
