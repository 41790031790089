import { CustomerFromEnum, NewDataAnalyzeDTO, UserStateEnum } from '@/api';
import { crmProxy } from '@/apis/api';
import DatetimePicker from '@/components/DatetimePicker';
import DepartmentSelect from '@/components/DepartmentSelect';
import FromSelect from '@/components/FromSelect';
import useCompany from '@/hooks/company';
import {
  findAllDepartmentId,
  findChildrenDepartment,
  flatDepartment,
  formatEndTime,
  formatStartTime,
  sortName,
} from '@/utils';
import { useRequest } from 'ahooks';
import { Button, Col, Row, Segmented, Space, Statistic, Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';

type Type = 'person' | 'department' | 'group' | 'company';

type Data = { id: I.ID; name: string } & Omit<
  NewDataAnalyzeDTO,
  'userName' | 'departmentId' | 'userId' | 'userState'
>;

type CompanyNewDataAnalyzeDTO = NewDataAnalyzeDTO & {
  companyId: I.ID;
  companyName: string;
  company: I.Company & { info: I.CompanyInfo };
};

const flatData = (
  validData: CompanyNewDataAnalyzeDTO[],
  id: I.ID,
  name: string,
) => {
  const value = validData.reduce<Omit<Data, 'id' | 'name'>>(
    (prev, cur) => {
      return {
        id: '',
        name: '',
        cnt: Number(prev.cnt) + Number(cur.cnt),
        star0: Number(prev.star0) + Number(cur.star0),
        star1: Number(prev.star1) + Number(cur.star1),
        star2: Number(prev.star2) + Number(cur.star2),
        star3: Number(prev.star3) + Number(cur.star3),
        star4: Number(prev.star4) + Number(cur.star4),
        star5: Number(prev.star5) + Number(cur.star5),
        stateSignedCount:
          Number(prev.stateSignedCount) + Number(cur.stateSignedCount),
        stateVisitedCount:
          Number(prev.stateVisitedCount) + Number(cur.stateVisitedCount),
        withAgentCount:
          Number(prev.withAgentCount) + Number(cur.withAgentCount),
        withAgentSignedCount:
          Number(prev.withAgentSignedCount) + Number(cur.withAgentSignedCount),
        withBrokerage: Number(prev.withBrokerage) + Number(cur.withBrokerage),
        withLoanCount: Number(prev.withLoanCount) + Number(cur.withLoanCount),
        withLoanAmount:
          Number(prev.withLoanAmount) + Number(cur.withLoanAmount),
      };
    },
    {
      cnt: 0,
      star0: 0,
      star1: 0,
      star2: 0,
      star3: 0,
      star4: 0,
      star5: 0,
      stateSignedCount: 0,
      stateVisitedCount: 0,
      withLoanAmount: 0,
      withAgentCount: 0,
      withAgentSignedCount: 0,
      withBrokerage: 0,
      withLoanCount: 0,
    },
  );
  return {
    ...value,
    name,
    id,
  };
};

const columns: ColumnType<Data>[] = [
  {
    dataIndex: 'name',
    align: 'left',
    sorter: (a, b) => sortName(a.name, b.name),
    width: 156,
  },
  {
    dataIndex: 'cnt',
    title: '客户总数',
    sorter: (a, b) => (a.cnt > b.cnt ? 1 : -1),
    width: 96,
  },
  {
    dataIndex: 'stateVisitedCount',
    sorter: (a, b) => (a.stateVisitedCount > b.stateVisitedCount ? 1 : -1),
    render: (_, dto) => {
      const percentage = !!dto.cnt
        ? ((Number(dto.stateVisitedCount) * 100.0) / Number(dto.cnt)).toFixed(2)
        : 0;
      return (
        <span>
          {dto.stateVisitedCount}（
          <Statistic
            value={percentage}
            suffix="%"
            style={{ display: 'inline-block' }}
            valueStyle={{ fontSize: '14px' }}
          />
          ）
        </span>
      );
    },
    title: '总上门量',
    width: 100,
  },
  {
    dataIndex: 'stateSignedCount',
    sorter: (a, b) => (a.stateSignedCount > b.stateSignedCount ? 1 : -1),
    title: '签约量',
    width: 100,
    render: (_, dto) => {
      const percentage = !!dto.stateVisitedCount
        ? (
            (Number(dto.stateSignedCount) * 100.0) /
            Number(dto.stateVisitedCount)
          ).toFixed(2)
        : 0;
      return (
        <span>
          {dto.stateSignedCount}（
          <Statistic
            valueStyle={{ fontSize: '14px' }}
            style={{ display: 'inline-block' }}
            value={percentage}
            suffix="%"
          />
          ）
        </span>
      );
    },
  },
  {
    dataIndex: 'withAgentCount',
    title: '拓展上门量',
    sorter: (a, b) => (a.withAgentCount > b.withAgentCount ? 1 : -1),
    width: 110,
  },
  {
    dataIndex: 'withAgentSignedCount',
    title: '拓展成功量',
    sorter: (a, b) =>
      a.withAgentSignedCount > b.withAgentSignedCount ? 1 : -1,
    width: 110,
  },
  {
    dataIndex: 'withLoanCount',
    title: '放款成功',
    width: 105,
    sorter: (a, b) => (a.withLoanCount > b.withLoanCount ? 1 : -1),
    render: (_, dto) => {
      return (
        <span>
          {dto.withLoanCount}（
          <Statistic
            style={{ display: 'inline-block' }}
            valueStyle={{ fontSize: '14px' }}
            value={Number(dto.withLoanAmount) / 100}
            prefix="¥"
          />
          ）
        </span>
      );
    },
  },
  {
    dataIndex: 'withBrokerage',
    title: '创收',
    width: 65,
    render: (v) => {
      return (
        <Statistic
          value={v / 100}
          prefix="¥"
          valueStyle={{ fontSize: '14px' }}
        />
      );
    },
    sorter: (a, b) =>
      Number(a.withBrokerage || 0) > Number(b.withBrokerage || 0) ? 1 : -1,
  },
  {
    title: '平均点位',
    width: 95,
    sorter: (a, b) => {
      const percentageA = !!a.withLoanAmount
        ? (Number(a.withBrokerage) * 100.0) / Number(a.withLoanAmount)
        : 0;
      const percentageB = !!b.withLoanAmount
        ? (Number(b.withBrokerage) * 100.0) / Number(b.withLoanAmount)
        : 0;
      return percentageA > percentageB ? 1 : -1;
    },
    render: (_, dto) => {
      const percentage = !!dto.withLoanAmount
        ? (
            (Number(dto.withBrokerage) * 100.0) /
            Number(dto.withLoanAmount)
          ).toFixed(2)
        : 0;
      return (
        <Statistic
          valueStyle={{ fontSize: '14px' }}
          suffix="%"
          value={percentage}
        />
      );
    },
  },
  ...['5', '4', '3', '2', '1', '0'].map((item) => ({
    title: `${item} 星`,
    dataIndex: `star${item}`,
    width: 65,
    sorter: (a: any, b: any) =>
      a[`star${item}Count`] > b[`star${item}Count`] ? 1 : -1,
  })),
  {
    title: '优质率',
    width: 80,
    sorter: (a, b) => {
      const cal = (item: Data) => {
        const denominator =
          Number(item.star0) +
          Number(item.star1) +
          Number(item.star2) +
          Number(item.star3) +
          Number(item.star4) +
          Number(item.star5);
        const molecule =
          Number(item.star5) + Number(item.star4) + Number(item.star3);
        const percentage = !!denominator ? (molecule * 100.0) / denominator : 0;
        return percentage;
      };
      return cal(a) > cal(b) ? 1 : -1;
    },
    render: (_, item) => {
      const denominator =
        Number(item.star0) +
        Number(item.star1) +
        Number(item.star2) +
        Number(item.star3) +
        Number(item.star4) +
        Number(item.star5);
      const molecule =
        Number(item.star5) + Number(item.star4) + Number(item.star3);
      const percentage = !!denominator
        ? ((molecule * 100.0) / denominator).toFixed(2)
        : 0;
      return (
        <Statistic
          value={percentage}
          suffix="%"
          valueStyle={{ fontSize: '14px' }}
        />
      );
    },
  },
];

interface CompanyAndDepartment {
  companyId: I.ID;
  departmentId: I.ID;
}

const defaultCreatedAt: [dayjs.Dayjs, dayjs.Dayjs] = [
  dayjs().startOf('month'),
  dayjs().endOf('month'),
];

export default function NewDataAnalyse() {
  const { loading: companyLoading, companies: currentCompanies } = useCompany();

  const [companyAndDepartment, setCompanyAndDepartment] = useState<
    CompanyAndDepartment[]
  >([]);

  const [type, setType] = useState<Type>('company');

  const [createdAt, setCreatedAt] =
    useState<[dayjs.Dayjs, dayjs.Dayjs]>(defaultCreatedAt);

  const [fromList, setFromList] = useState<CustomerFromEnum>();

  const {
    data: rawData,
    loading: requestLoading,
    refreshAsync,
  } = useRequest(
    () => {
      return Promise.all(
        (currentCompanies || [])
          // .filter((c) => {
          //   if (companyAndDepartment.length === 0) {
          //     return true;
          //   }
          //   // 找到所选的所有的 companyId
          //   return companyAndDepartment
          //     .map((cd) => cd.companyId)
          //     .includes(c.id);
          // })
          .map((c) => {
            return crmProxy('GET', c.id, 'new_data_analyze', {
              params: {
                start: formatStartTime(createdAt[0], true),
                end: formatEndTime(createdAt[1], true),
                from: fromList,
              },
            }).then((result) => {
              return (
                (result as NewDataAnalyzeDTO[])
                  // .filter((item) => {
                  //   return (
                  //     companyAndDepartment.find((cd) => cd.companyId === c.id)
                  //       ?.departmentId === item.departmentId
                  //   );
                  // })
                  .map(
                    (item) =>
                      ({
                        ...item,
                        companyId: c.id,
                        companyName: c.name,
                        company: c,
                      } as CompanyNewDataAnalyzeDTO),
                  )
              );
            });
          }),
      ).then((result) => {
        return result.flat();
      });
    },
    {
      refreshDeps: [createdAt, fromList, currentCompanies],
    },
  );

  const companies = useMemo(() => {
    if (companyAndDepartment.length === 0) {
      return currentCompanies || [];
    }
    return (currentCompanies || []).filter(
      (c) => companyAndDepartment.findIndex((cd) => cd.companyId === c.id) > -1,
    );
  }, [currentCompanies, companyAndDepartment]);

  const data = useMemo<Data[]>(() => {
    if (!rawData) {
      return [];
    }

    const validData = rawData;

    switch (type) {
      case 'company': {
        // 按照公司排序
        return (
          companies.map((item) => {
            const value = validData.filter((i) => i.companyId === item.id);
            return flatData(value, item.id, item.name);
          }) || []
        );
      }
      case 'department': {
        // 按照部门就要先按照公司排序
        const allDirectChildrent =
          (companyAndDepartment?.length || 0) > 0
            ? (companyAndDepartment || [])
                .map((c) => {
                  const company = companies?.find(
                    (item) => item.id === c.companyId,
                  );
                  if (!company) {
                    throw new Error('公司不存在');
                  }
                  return company.info.departments
                    .filter((dep) => !dep.parentId)
                    .filter((dep) => {
                      if (c.departmentId) {
                        return dep.id === c.departmentId;
                      }
                      return true;
                    })
                    .map((dep) => ({
                      ...dep,
                      companyId: company.id,
                      companyName: company.name,
                      company,
                      name: `${company.name}-${dep.name}`,
                      id: `${company.id}-${dep.id}`,
                      depId: dep.id,
                    }));
                })
                .flat()
            : companies
                ?.map((c) => {
                  return c.info.departments
                    .filter((dep) => !dep.parentId)
                    .map((dep) => ({
                      ...dep,
                      companyId: c.id,
                      companyName: c.name,
                      company: c,
                      name: `${c.name}-${dep.name}`,
                      id: `${c.id}-${dep.id}`,
                      depId: dep.id,
                    }));
                })
                .flat();
        return (
          Object.values(
            allDirectChildrent.reduce((acc, current) => {
              acc[current.id] = current;
              return acc;
            }, {} as Record<string, (typeof allDirectChildrent)[0]>),
          )
            ?.sort((a, b) => sortName(a.name, b.name))
            .map((item) => {
              const name = `${flatDepartment(
                item.company.info.departments,
                item.id,
              )
                .filter((v) => v.id !== item.id)
                .map((item) => item.name)
                .reverse()
                .join('/')} ${item.name}`;

              const children = findAllDepartmentId(
                item.company.info.departments,
                item.depId,
              ).map((dep) => `${item.companyId}-${dep.id}`);

              const value = validData.filter((item) =>
                children.includes(`${item.companyId}-${item.departmentId}`),
              );
              return flatData(value, item.id, name);
            }) || []
        );
      }
      case 'group': {
        // 找到所有的一级小组
        const selectedDepartments =
          (companyAndDepartment?.length || 0) > 0
            ? (companyAndDepartment || [])
                // .filter((c) => c.id === companyAndDepartment?.[0])
                .map((c) => {
                  const company = companies?.find(
                    (item) => item.id === c.companyId,
                  );
                  if (!company) {
                    throw new Error('公司不存在');
                  }
                  return findAllDepartmentId(
                    company?.info.departments,
                    c.departmentId,
                  ).map((dep) => ({ company, dep }));
                })
                .flat()
            : companies
                ?.map((c) =>
                  findAllDepartmentId(c.info.departments, null).map((dep) => ({
                    company: c,
                    dep,
                  })),
                )
                .flat();
        const allDeps = Object.values(
          selectedDepartments.reduce((acc, current) => {
            acc[`${current.company.id}-${current.dep.id}`] = current;
            return acc;
          }, {} as Record<string, { company: I.Company & { info: I.CompanyInfo }; dep: I.Department }>),
        )
          ?.filter(
            (item) =>
              findChildrenDepartment(item.company.info.departments, item.dep.id)
                .length === 0,
          )
          .map((item) => {
            return {
              ...item,
              id: `${item.company.id}-${item.dep.id}`,
            };
          });

        return (
          allDeps?.map((item) => {
            const name = `${item.company.name} ${flatDepartment(
              item.company.info.departments,
              item.dep.id,
            )
              .filter((v) => v.id !== item.id)
              .map((item) => item.name)
              .reverse()
              .join('/')}`;
            const value = validData.filter((i) => {
              return (
                i.departmentId === item.dep.id &&
                i.companyId === item.company.id
              );
            });
            return flatData(value, item.id, name);
          }) || []
        );
      }
    }

    if (companyAndDepartment.length > 0) {
      const selectedDepartments = companyAndDepartment
        .map((item) => {
          const company = currentCompanies?.find(
            (c) => c.id === item.companyId,
          );
          if (!company) {
            throw new Error('公司不存在');
          }
          // 找到选择的部门的所有的部门
          return findAllDepartmentId(
            company.info.departments,
            item.departmentId,
          ).map((dep) => ({
            depId: dep.id,
            name: dep.name,
            id: `${item.companyId}-${dep.id}`,
            company,
          }));
        })
        .flat();
      const uniqDeps = Object.values(
        selectedDepartments.reduce((acc, current) => {
          acc[current.id] = current;
          return acc;
        }, {} as Record<string, (typeof selectedDepartments)[0]>),
      );
      return validData
        .filter((user) => user.userState === UserStateEnum.ACTIVE)
        .filter(
          (user) =>
            uniqDeps.findIndex(
              (dep) => dep.id === `${user.companyId}-${user.departmentId}`,
            ) > -1,
        )
        .map((item) => {
          const company = currentCompanies?.find(
            (c) => c.id === item.companyId,
          );
          if (!company) {
            throw new Error('公司不存在');
          }
          const name = `${company.name}-${flatDepartment(
            item.company.info.departments,
            item.departmentId,
          )
            .map((item) => item.name)
            .reverse()
            .join('/')} ${item.userName}`;

          return {
            ...item,
            id: `${item.companyId}-${item.userId}`,
            name,
          };
        });
    }

    return validData
      .filter((user) => user.userState === UserStateEnum.ACTIVE) // 过滤有效的用户
      ?.map((item) => {
        const company = currentCompanies?.find((c) => c.id === item.companyId);
        if (!company) {
          throw new Error('公司不存在');
        }
        const name = `${company.name}-${flatDepartment(
          item.company.info.departments,
          item.departmentId,
        )
          .map((item) => item.name)
          .reverse()
          .join('/')} ${item.userName}`;

        return {
          ...item,
          id: `${item.companyId}-${item.userId}`,
          name,
        };
      });
  }, [rawData, type, companyAndDepartment, companies, currentCompanies]);

  const loading = companyLoading || requestLoading;
  return (
    <Row>
      <Col span={24}>
        <Space>
          <FromSelect
            value={fromList}
            onChange={(v) => setFromList(v)}
            disabled={loading}
          />
          <Segmented
            options={[
              {
                label: '个人',
                value: 'person',
              },
              {
                label: '小组',
                value: 'group',
              },
              {
                label: '区域',
                value: 'department',
              },
              {
                label: '公司',
                value: 'company',
              },
            ]}
            value={type}
            onChange={(v) => setType(v as Type)}
            disabled={loading}
          />

          <DepartmentSelect
            multiple
            disabled={loading}
            value={companyAndDepartment.map(
              (item) => `${item.companyId}-${item.departmentId}`,
            )}
            onClear={() => {
              setCompanyAndDepartment([]);
            }}
            onChange={(v: string[]) => {
              setCompanyAndDepartment(
                v.map((item) => {
                  const vv = item.split('-').map((vv: string) => parseInt(vv));
                  return {
                    companyId: vv[0],
                    departmentId: vv[1],
                  };
                }),
              );
            }}
          />
          <DatetimePicker
            disabled={loading}
            value={createdAt}
            onChange={(v) => {
              const start = v.startedAt
                ? dayjs(v.startedAt)
                : defaultCreatedAt[0];
              const end = v.endedAt ? dayjs(v.endedAt) : defaultCreatedAt[1];
              setCreatedAt([start, end]);
            }}
          />
          <Button type="primary" loading={loading} onClick={refreshAsync}>
            查询
          </Button>
        </Space>
      </Col>
      <Col span={24} style={{ marginTop: '24px' }}>
        <Table
          summary={(row) => {
            return (
              <Table.Summary>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>总计</Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="right">
                    {row.reduce((prev, cur) => prev + Number(cur.cnt), 0)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="right">
                    {/* 总上门量 */}
                    {row.reduce(
                      (prev, cur) => prev + Number(cur.stateVisitedCount),
                      0,
                    )}
                    （
                    <Statistic
                      style={{ display: 'inline-block' }}
                      value={
                        (row.reduce((prev, dto) => {
                          return prev + Number(dto.stateVisitedCount || 0);
                        }, 0) /
                          row.reduce(
                            (prev, dto) => prev + Number(dto.cnt || 0),
                            0,
                          )) *
                        100.0
                      }
                      precision={2}
                      valueStyle={{
                        fontSize: '12px',
                      }}
                      suffix="%"
                    />
                    ）
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="right">
                    {/* 签约量 */}
                    {row.reduce(
                      (prev, cur) => prev + Number(cur.stateSignedCount),
                      0,
                    )}
                    （
                    <Statistic
                      style={{ display: 'inline-block' }}
                      value={
                        (row.reduce((prev, dto) => {
                          return prev + Number(dto.stateSignedCount || 0);
                        }, 0) /
                          row.reduce(
                            (prev, dto) =>
                              prev + Number(dto.stateVisitedCount || 0),
                            0,
                          )) *
                          100.0 || 0
                      }
                      precision={2}
                      valueStyle={{
                        fontSize: '12px',
                      }}
                      suffix="%"
                    />
                    ）
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} align="right">
                    {row.reduce(
                      (prev, cur) => prev + Number(cur.withAgentCount),
                      0,
                    )}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} align="right">
                    {row.reduce(
                      (prev, cur) => prev + Number(cur.withAgentSignedCount),
                      0,
                    )}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} align="right">
                    {/* 放款成功 */}
                    <span>
                      {row.reduce(
                        (prev, cur) => prev + Number(cur.withLoanCount),
                        0,
                      )}
                      （
                      <Statistic
                        style={{ display: 'inline-block' }}
                        value={
                          row.reduce(
                            (prev, cur) => prev + Number(cur.withLoanAmount),
                            0,
                          ) / 100
                        }
                        prefix="¥"
                        valueStyle={{ fontSize: '14px' }}
                        precision={2}
                      />
                      ）
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align="right">
                    {/* 创收 */}
                    <Statistic
                      value={
                        row.reduce(
                          (prev, cur) => prev + Number(cur.withBrokerage),
                          0,
                        ) / 100
                      }
                      prefix="¥"
                      valueStyle={{ fontSize: '14px' }}
                      precision={2}
                    />
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align="right">
                    {/* 平均点位 */}
                    {(
                      (row.reduce(
                        (prev, cur) => prev + Number(cur.withBrokerage),
                        0,
                      ) /
                        row.reduce(
                          (prev, cur) => prev + Number(cur.withLoanAmount),
                          0,
                        )) *
                        100 || 0
                    ).toFixed(2)}
                    %
                  </Table.Summary.Cell>
                  {[5, 4, 3, 2, 1, 0].map((star) => (
                    <Table.Summary.Cell
                      index={star + 8}
                      key={star}
                      align="right"
                    >
                      {row.reduce(
                        (prev, cur: any) => prev + Number(cur[`star${star}`]),
                        0,
                      )}
                    </Table.Summary.Cell>
                  ))}
                  <Table.Summary.Cell index={15} align="right">
                    {(
                      (row.reduce((prev, item) => {
                        const molecule =
                          Number(item.star5) +
                          Number(item.star4) +
                          Number(item.star3);
                        return prev + molecule;
                      }, 0) /
                        row.reduce((prev, item) => {
                          return (
                            prev +
                            Number(item.star0) +
                            Number(item.star1) +
                            Number(item.star2) +
                            Number(item.star3) +
                            Number(item.star4) +
                            Number(item.star5)
                          );
                        }, 0)) *
                      100.0
                    ).toFixed(2)}
                    %
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
          loading={loading}
          rowKey={'id'}
          dataSource={data}
          columns={columns.map((item) => {
            const align = item.align || 'right';
            return {
              ...item,
              align,
            };
          })}
          size="small"
          bordered
          pagination={false}
        />
      </Col>
    </Row>
  );
}
