import { isClose, push } from '@/apis/api';
import { useRequest } from 'ahooks';
import { Button, Checkbox, Form, Input, InputNumber, message } from 'antd';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export default function TestPush() {
  const navigate = useNavigate();
  const { source_id } = useParams<{ source_id: string }>();
  const { search } = useLocation();

  const qs = new URLSearchParams(search);

  const token = qs.get('token');
  const sourceName = qs.get('name');

  const channel = qs.get('channel');

  const [form] = Form.useForm();

  const {
    data: isClosed,
    loading: closedLoading,
    runAsync: checkClose,
  } = useRequest(
    () => {
      return isClose(channel || '', token || '');
    },
    {
      refreshDeps: [token, channel],
    },
  );

  if (isClosed) {
    message.error('该渠道已关闭，请勿使用');
  }

  const { loading: pushLoading, runAsync: submit } = useRequest(push, {
    manual: true,
  });

  const loading = pushLoading || closedLoading;

  useEffect(() => {
    if (!source_id || !token) {
      navigate('/404');
    }
  }, [source_id, navigate, token]);

  const onFinish = (
    values: Omit<Parameters<typeof push>[1], 'extra'> & {
      extra: (keyof Parameters<typeof push>[1]['extra'])[];
    },
  ) => {
    if (source_id && token) {
      return checkClose().then((closed) => {
        if (closed) {
          message.error('该渠道已关闭，无法使用');
          return;
        }
        return submit(source_id, {
          ...values,
          quota: values.quota && values.quota * 10000,
          token,
          extra: values?.extra?.reduce((acc, cur) => {
            acc[cur] = true;
            return acc;
          }, {} as Parameters<typeof push>[1]['extra']),
        })
          .then(() => {
            message.success('添加成功');
            form.resetFields();
          })
          .catch((e) => {
            message.error(e.message);
          });
      });
    }
  };

  return (
    <div style={{ width: '100vw' }}>
      <div style={{ textAlign: 'center', fontSize: 24, margin: '50px 0' }}>
        {sourceName}-{channel}
      </div>
      <Form
        disabled={isClosed}
        onFinish={onFinish}
        form={form}
        name="basic"
        initialValues={{
          channel,
        }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600, margin: '10px auto' }}
      >
        <Form.Item label="客户姓名" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="客户电话"
          name="mobile"
          rules={[
            { required: true },
            {
              pattern: /^1\d{10}$/,
              message: '手机号格式错误！',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="来源渠道"
          name="channel"
          rules={[{ required: true }]}
          hidden
        >
          <Input />
        </Form.Item>
        <Form.Item label="申请额度" name="quota">
          <InputNumber
            addonBefore="¥"
            min="0"
            step={1}
            controls={false}
            precision={2}
            addonAfter="万"
          />
        </Form.Item>
        <Form.Item label="备注" name="remark" rules={[{ max: 1024 }]}>
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="其他信息" name="extra">
          <Checkbox.Group>
            <Checkbox value="has_house">有房</Checkbox>
            <Checkbox value="has_car">有车</Checkbox>
            <Checkbox value="has_credit_card">有信用卡</Checkbox>
            <Checkbox value="has_provident_fund">有公积金</Checkbox>
            <Checkbox value="has_social_security">有社保</Checkbox>
            <Checkbox value="has_insurance">有保险</Checkbox>
            <Checkbox value="has_business_license">有营业执照</Checkbox>
            <Checkbox value="has_wages">工资流水</Checkbox>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            提交
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
